import React from 'react'

// const Rooster = () => {
//   return (
//     <div className='grid grid-cols-6'>
//         <div className='flex flex-col items-end mr-2'>
//             <p className='border-b border-gray-500'></p>
//             <div className='h-6'></div>
//             <div className='h-6'>9:00</div>
//             <div className='h-6'></div>
//             <div className='h-6'></div>
//             <div className='h-6'>10:00</div>
//             <div className='h-6'></div>
//             <div className='h-6'></div>
//             <div className='h-6'>11:00</div>
//             <div className='h-6'></div>
//             <div className='h-6'></div>
//             <div className='h-6'>18:00</div>
//             <div className='h-6'></div>
//             <div className='h-6'></div>
//             <div className='h-6'>19:00</div>
//             <div className='h-6'></div>
//             <div className='h-6'></div>
//             <div className='h-6'>20:00</div>
//             <div className='h-6'></div>
//             <div className='h-6'></div>
//             <div className='h-6'>21:00</div>
//         </div>
//         <div>
//             <p className='border-b border-gray-500 text-sm text-gray-500 uppercase'>Maandag</p>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='bg-sky-100 h-24 p-2 w-11/12 mx-auto'>
//                 <p className='text-xs'>19:15 - 20:30</p>
//                 <p className='text-sm font-semibold'>Privé yoga</p>
//             </div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//         </div>
//         <div>
//             <p className='border-b border-gray-500 text-sm text-gray-500 uppercase'>Dinsdag</p>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='bg-sky-100 h-24 p-2 w-11/12 mx-auto'>
//                 <p className='text-xs'>19:30 - 20:45</p>
//                 <p className='text-sm font-semibold'>Ansunara yoga</p>
//                 <p className='text-xs'>Izegem, Nachtegaalstraat 1.1</p>
//             </div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//         </div>
//         <div>
//             <p className='border-b border-gray-500 text-sm text-gray-500 uppercase'>Woensdag</p>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='bg-sky-100 h-[72px] p-2 w-11/12 mx-auto'>
//                 <p className='text-xs'>18:45 - 19:45</p>
//                 <p className='text-sm font-semibold'>Privé yoga</p>
//             </div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='bg-sky-100 h-24 p-2 w-11/12 mx-auto'>
//                 <p className='text-xs'>20:00 - 21:30</p>
//                 <p className='text-sm font-semibold'>Privé yoga</p>
//             </div>
//         </div>
//         <div>
//             <p className='border-b border-gray-500 text-sm text-gray-500 uppercase'>Woensdag</p>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>
                
//             <div className='bg-sky-100 h-[72px] p-2 w-11/12 mx-auto'>
//                 <p className='text-xs'>18:15 - 19:30</p>
//                 <p className='text-sm font-semibold'>Easy Flow yoga</p>
//                 <p className='text-xs'>Izegem, Nachtegaalstraat 1.1</p>
//             </div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='bg-sky-100 h-24 p-2 w-11/12 mx-auto'>
//                 <p className='text-xs'>19:45 - 21:00</p>
//                 <p className='text-sm font-semibold'>Yin yoga</p>
//                 <p className='text-xs'>Izegem, Nachtegaalstraat 1.1</p>
//             </div>
//             <div className='border-b border-gray-400 h-6'></div>
//             <div className='border-b border-gray-400 h-6'></div>     
//         </div>
//     </div>
//   )
// }

const Rooster = () => {
    return (
    <div className='overflow-auto grid grid-cols-[repeat(6,minmax(100px,1fr))] -translate-x-12'>
        <div>
            <div className='h-6'></div>
            <div className='h-6 flex justify-end mr-2'><p>9:00</p></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'><p>10:00</p></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'><p>11:00</p></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'><p>18:00</p></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'><p>19:00</p></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'><p>20:00</p></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'><p>21:00</p></div>
            <div className='h-6 flex justify-end mr-2'></div>
            <div className='h-6 flex justify-end mr-2'></div>
        </div>
        <div>
            <p className='font-[Lato] uppercase text-xs text-gray-500 border-b border-gray-400 py-1'>Maandag</p>
            {Array(17).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
            <div className='h-[calc(6*24px)] border border-sky-300 bg-sky-100 p-2'>
                <p className='text-xs text-black/50'>19:00 - 20:30</p>
                <p className='text-sm font-semibold'>Privé yoga</p>
            </div>
            {Array(4).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
        </div>
        <div>
            <p className='font-[Lato] uppercase text-xs text-gray-500 border-b border-gray-400 py-1'>Dinsdag</p>
            {Array(18).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
            <a href="mailto:yogametnadine@gmail.com?subject=Donderdag - Anusara yoga - 19:30 - 20:45">
            <div className='h-[calc(6*24px)] border border-sky-300 bg-sky-100 p-2 cursor-pointer duration-100 hover:bg-sky-200'>
                <p className='text-xs text-black/50'>19:30 - 20:45</p>
                <p className='text-sm font-semibold'>Anusara yoga</p>
                <p className='text-xs text-black/50'>Izegem, Nachtegaalstraat 1.1</p>
            </div>
            </a>
            {Array(3).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
        </div>
        <div>
            <p className='font-[Lato] uppercase text-xs text-gray-500 border-b border-gray-400 py-1'>Woensdag</p>
            {Array(15).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
            <div className='h-[calc(4*24px)] border border-sky-300 bg-sky-100 p-2'>
                <p className='text-xs text-black/50'>18:45 - 19:45</p>
                <p className='text-sm font-semibold'>Privé yoga</p>
            </div>
            <div className='h-6 border-b border-gray-300'></div>
            <div className='h-[calc(7*24px)] border border-sky-300 bg-sky-100 p-2'>
            <p className='text-xs text-black/50'>20:00 - 21:30</p>
                <p className='text-sm font-semibold'>Privé yoga</p>
            </div>
        </div>
        <div>
            <p className='font-[Lato] uppercase text-xs text-gray-500 border-b border-gray-400 py-1'>Donderdag</p>
            {Array(13).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
            <a href="mailto:yogametnadine@gmail.com?subject=Donderdag - Easy Flow yoga - 18:15 - 19:30">
            <div className='h-[calc(5*24px)] border border-sky-300 bg-sky-100 p-2 cursor-pointer duration-100 hover:bg-sky-200'>
                <p className='text-xs text-black/50'>18:15 - 19:30</p>
                <p className='text-sm font-semibold'>Easy Flow yoga</p>
                <p className='text-xs text-black/50'>Izegem, Nachtegaalstraat 1.1</p>
            </div>
            </a>
            <div className='h-6 border-b border-gray-300'></div>
            <a href="mailto:yogametnadine@gmail.com?subject=Donderdag - Yin yoga - 19:45 - 21:00">
            <div className='h-[calc(6*24px)] border border-sky-300 bg-sky-100 p-2 cursor-pointer duration-100 hover:bg-sky-200'>
                <p className='text-xs text-black/50'>19:45 - 21:00</p>
                <p className='text-sm font-semibold'>Yin yoga</p>
                <p className='text-xs text-black/50'>Izegem, Nachtegaalstraat 1.1</p>
            </div>
            </a>
            {Array(2).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
        </div>
        <div>
            <p className='font-[Lato] uppercase text-xs text-gray-500 border-b border-gray-400 py-1'>Vrijdag</p>
            <a href="mailto:yogametnadine@gmail.com?subject=Donderdag - Easy Flow yoga - 9:00 - 10:15">
            <div className='h-[calc(5*24px)] border border-sky-300 bg-sky-100 p-2 cursor-pointer duration-100 hover:bg-sky-200'>
                <p className='text-xs text-black/50'>9:00 - 10:15</p>
                <p className='text-sm font-semibold'>Easy Flow yoga</p>
                <p className='text-xs text-black/50'>Izegem, Nachtegaalstraat 1.1</p>
            </div>
            </a>
            {Array(22).fill('').map(()=>(
                <div className='h-6 border-b border-gray-300'></div>
            ))}
        </div>
    </div>
    )
}

export default Rooster