import React from 'react'
import contact_img from '../assets/images/contact.jpg'
import facebook from '../assets/images/facebook.png'
import instagram from '../assets/images/instagram.png'
import youtube from '../assets/images/youtube.png'

const Contact = () => {
  return (
    <>
    <div className='py-32 bg-sky-100'></div>
    <section id='contact' className='h-screen bg-amber-50 flex justify-center items-center'>
        <div className='flex-col sm:flex-row max-w-4xl flex items-center gap-6'>
            <div className='w-full h-64 sm:w-96 sm:h-96'><img className='w-full h-full object-cover' src={contact_img} alt="contact"/></div>
            <div className='flex flex-col gap-4'>
                <h1 className='text-xl font-semibold uppercase font-[Lato]'>Contacteer mij</h1>
                <p className='text-sm'>Contacteer mij voor al uw vragen over yoga, groeps- en privélessen. <br></br>Alle niveau’s zijn welkom.</p>
                <p className='text-sm'>Telefoon: 0476 75 63 60<br></br>E-mail: <a href="mailto:yogametnadine@gmail.com">yogametnadine@gmail.com</a><br></br>Locatie: Nachtegaalstraat 1.1, Izegem</p>
                <p className='text-sm'>BTW BE0855 557 519</p>
                <ul className='mt-8 grid grid-cols-3 w-fit gap-2'>
                    <li className='group flex items-center justify-center'><a href="https://www.facebook.com/YogametNadine/" target="_blank"><img className='w-3/5 opacity-50 group-hover:opacity-100' src={facebook} alt="facebook"/></a></li>
                    <li className='group flex items-center justify-center'><a href="https://www.instagram.com/yogametnadine/" target="_blank"><img className='w-3/5 opacity-50 group-hover:opacity-100' src={instagram} alt="instagram"/></a></li>
                    <li className='group flex items-center justify-center'><a href="https://www.youtube.com/channel/UC4nw6GFzg6FQ4QdxhQjtbFQ" target="_blank"><img className='w-3/5 opacity-50 group-hover:opacity-100' src={youtube} alt="youtube"/></a></li>
                </ul>
            </div>
        </div>
    </section>
    </>
  )
}

export default Contact