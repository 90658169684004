import React from 'react';
import './App.css';
import Home from './pages/Home';
import Navigation from './components/Navigation';
import About from './pages/About';
import Planning from './pages/Planning';
import Prices from './pages/Prices';
import Contact from './pages/Contact';

function App() {
  return (
    <>
    <Navigation />
    <Home />
    <About />
    <Planning />
    <Prices />
    <Contact />
    </>
  );
}

export default App;
