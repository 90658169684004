import React from 'react'
import styles from '../styles/Home.module.scss'
import love from '../assets/images/love.png'

const Home = () => {
  return (
    <section id="home" className={`${styles.home} relative w-full h-full bg-neutral-50`}>
        <section className='h-full text-gray-800 uppercase text-center pt-60'>
            <h1 className="font-['Lato'] text-4xl">happy met yoga</h1>
            <h2 className="font-['Lato'] text-xl">yoga met nadine - izegem</h2>
        </section>
        <video className='absolute z-10 outline-none top-80 left-1/2 -translate-x-1/2' width="475" height="320" controls>
            <source src="https://yogametnadine.be/assets/video/introvideoYogaMetNadine.mp4" type="video/mp4"/>
            <source src="https://yogametnadine.be/assets/video/introvideoYogaMetNadine.webm" type="video/webm"/>
            <source src="https://yogametnadine.be/assets/video/introvideoYogaMetNadine.ogv" type="video/ogg"/>
        </video>
        <a href="#over" className='absolute left-1/2 -translate-x-1/2 bottom-5 w-8 h-8'>
            <img src={love} alt="love" />
        </a>
    </section>
  )
}

export default Home