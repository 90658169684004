import React from 'react'
import over from '../assets/images/over.jpg'
import logoSmall from '../assets/images/logoSmall.png';

const About = () => {
  return (
    <section className='bg-sky-100 w-full min-h-full pb-6' id="over">
	<article className='flex-col sm:flex-row flex max-w-4xl mx-auto'>
		<div className='sm:w-2/5 h-[500px] object-cover'><img className='object-cover h-full' src={over} alt="over"/></div>
		<section className='sm:w-3/5 h-full p-5 sm:mt-16'>
            <h1 className="bg-sky-100 -translate-y-24 sm:-translate-y-0 sm:bg-transparent font-medium font-['Hepta_Slab']">
                "A photographer gets people to pose for him. <br></br>
                A yoga instructor gets people to pose for themselves." <br></br>
                <span className='text-xs'>~ Terri Guillemets</span>
            </h1>
            <p className='bg-sky-100 -translate-y-24 sm:-translate-y-0 sm:bg-transparent text-sm mt-8 leading-relaxed'>
                <span className='font-medium'>In 2013 volgde ik een opleiding anusara yoga in Brugge.</span> 
                Ik was op zoek naar meer verdieping in mezelf en in het leven. Jarenlang beoefende ik reeds pilates en yoga in een fitness. Pas toen ik een workshop volgde in Brugge bij <span className='font-medium'>Katryn Budig,</span> voelde ik een extra dimensie. Een gevoel van verbinding in mezelf en rust. Na meerdere workshops in binnen- en buitenland bij oa. Marc St Pierre, David Lurey, Kenny Graham, Anat Geiger, Marcel Van De Vis, Tiffany Cruikshank en Meghan Currie geef ik <span className='underline'>sinds 2014 les.</span> De lessen zijn nog steeds anusara geïnspireerd met mijn eigen unieke toevoegingen.</p></section>
	</article>
	<section className='mx-4 mt-10 max-w-4xl sm:mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4'>
		<article className='bg-sky-200 p-4 h-fit'>
			<header className='border-b border-sky-400 pb-4 mb-4 flex justify-center'>
				<h1 className='text-center uppercase font-semibold'>Anusara yoga</h1>
			</header>
			<section>
				<p>Komt van hatha yoga en betekent “flowing with grace”. – meegaan met de stroom naar Zaligheid. Met een open hart, in gracieuze stijl bewegen naar een gevoel van geluk en vreugde. </p>
				<p>Dit is tenslotte de essentie van het leven. Het gedrag is puur, spiritueel en krachtig.</p>
				<p>Grondlegger is de Amerikaan John Friend die in 1997 zijn school begon.</p>
			</section>
			<footer className='border-t border-sky-400 pt-4 mt-4 '>
				<div className='flex justify-center'><img src={logoSmall} alt="Logo"/></div>
			</footer>
		</article>
		<article className='bg-sky-200 p-4 h-fit'>
			<header className='border-b border-sky-400 pb-4 mb-4 flex justify-center'>
				<h1 className='text-center uppercase font-semibold'>easy flow yoga</h1>
			</header>
			<section>
				<p>Gebaseerd op anusara yoga maar op een rustiger tempo en eenvoudigere oefeningen.</p>
			</section>
			<footer className='border-t border-sky-400 pt-4 mt-4 '>
				<div className='flex justify-center'><img src={logoSmall} alt="Logo"/></div>
			</footer>
		</article>
		<article className='bg-sky-200 p-4 h-fit'>
			<header className='border-b border-sky-400 pb-4 mb-4 flex justify-center'>
				<h1 className='text-center uppercase font-semibold'>yin yoga</h1>
			</header>
			<section>
				<p>Een rustige vorm van yoga die zich richt op het versterken en versoepelen van het bindweefsel en de gewrichten.</p>
				<p>De oefeningen rekken de dieperliggende spieren en maken het bekkengebied los. In eerste instantie zijn de houdingen bedoeld om langer in de meditatiehouding te kunnen blijven mediteren.</p>
				<p>De posities worden langer aangehouden, 3 – 5 min.</p>
				<p>Er wordt gewerkt op de energiebanen zoals in de accupunctuur.</p>
				<p>Grondlegger is de amerikaan Paul Grilley die sinds 1980  onderwijst in yoga en anatomie.</p>
			</section>
			<footer className='border-t border-sky-400 pt-4 mt-4 '>
				<div className='flex justify-center'><img src={logoSmall} alt="Logo"/></div>
			</footer>
		</article>
		<article className='bg-sky-200 p-4 h-fit'>
			<header className='border-b border-sky-400 pb-4 mb-4 flex justify-center'>
				<h1 className='text-center uppercase font-semibold'>bedrijfs- &amp; Prive yoga</h1>
			</header>
			<section>
				<p>In uw bedrijf of op een andere locatie. Contacteer mij gerust voor meer info.</p>
			</section>
			<footer className='border-t border-sky-400 pt-4 mt-4 '>
				<div className='flex justify-center'><img src={logoSmall} alt="Logo"/></div>
			</footer>
		</article>
	</section>
    <p className='mt-8 max-w-4xl w-fit mx-4 sm:mx-auto text-center'>In alle lessen is introspectie het belangrijkste waarbij de aandacht voor meditatie een anker is, net als de ademhaling.</p>
</section>
  )
}

export default About