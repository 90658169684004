import React from 'react'
import Rooster from '../components/Rooster'

const Planning = () => {
  return (
    <section id="lessen" className='bg-amber-50 pt-32 pb-16 '>
        <div className='max-w-4xl mx-auto flex flex-col justify-center items-center'>
            <h1 className='uppercase text-xl text-gray-800 font-[Lato]'>Alle yoga lessen</h1>
            <p className='text-gray-600 mt-4 text-sm'>Klik op een van de lessen om je in te schrijven.</p>
        
            <div className='relative w-full mt-16'>
                <Rooster />
            </div>
        </div>
    </section>
  )
}

export default Planning