import {useState} from 'react'

import { X } from 'lucide-react'

import logoLarge from '../assets/images/logoLarge.png'
import mobileMenu from '../assets/images/mobileMenu.png'

const closeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>

const Navigation = () => {
    const [is_open, setis_open] = useState(false)

  return (
    <>
    <nav className='hidden sm:block fixed z-10 top-9 left-0 right-0 w-full h-24'>
        <ul className='list-none text-center flex justify-center items-center'>
            <li className='inline-block mx-3'>
                <a href="#over" className='text-gray-800 uppercase align-middle'>over mezelf</a>
            </li>
            <li className='inline-block mx-3'>
                <a href="#lessen" className='text-gray-800 uppercase align-middle'>yoga lessen</a>
            </li>
            <li className='inline-block mx-3'>
                <a href="#home" className='text-gray-800 uppercase align-middle'><img className='w-24 h-24' src={logoLarge} alt="Logo"/></a>
            </li>
            <li className='inline-block mx-3'>
                <a href="#prijzen" className='text-gray-800 uppercase align-middle'>de prijzen</a>
            </li>
            <li className='inline-block mx-3'>
                <a href="#contact" className='text-gray-800 uppercase align-middle'>contacteer mij</a>
            </li>
        </ul>
    </nav>

    <nav className='flex justify-between w-full sm:hidden fixed z-20 p-4'>
        <a href="home"><img className='w-10 h-10' src={logoLarge} alt="Logo"/></a>
        <button onClick={()=>{setis_open(true)}}>
            <img src={mobileMenu} alt="mobile menu"/>
        </button>

        <div className={`${is_open ? 'w-11/12' : 'w-0 px-0'} overflow-hidden fixed top-0 right-0 h-full duration-200 bg-sky-100 p-4`}>
            <button className='w-10 h-10 mb-4' onClick={()=>{setis_open(false)}}>{closeIcon}</button>

            <ul>
                <li className='list-none mb-5'><a onClick={()=>{setis_open(false)}} className='text-gray-800 font-light uppercase' href="#home">home</a></li>
                <li className='list-none mb-5'><a onClick={()=>{setis_open(false)}} className='text-gray-800 font-light uppercase' href="#over">over mezelf</a></li>
                <li className='list-none mb-5'><a onClick={()=>{setis_open(false)}} className='text-gray-800 font-light uppercase' href="#lessen">yoga lessen</a></li>
                <li className='list-none mb-5'><a onClick={()=>{setis_open(false)}} className='text-gray-800 font-light uppercase' href="#prijzen">de prijzen</a></li>
                <li className='list-none mb-5'><a onClick={()=>{setis_open(false)}} className='text-gray-800 font-light uppercase' href="#contact">contacteer mij</a></li>
            </ul>

        </div>

    </nav>
    </>
  )
}

export default Navigation