import React from 'react'
import prijzen from '../assets/images/prijzen.jpg';

const Prices = () => {
  return (
    <section id="prijzen" className='relative'>
        <img className='min-h-[600px] w-full object-cover' src={prijzen} alt="prijzen"/>
        <div className='absolute bottom-0 z-10 p-4 bg-sky-100 flex flex-col items-center justify-center left-1/2 -translate-x-1/2'>
            <h1 className='mb-4 uppercase font-[Lato] font-bold text-2xl'>Prijzen</h1>
            <p className='text-center'>De prijs voor een losse les bedraagt € 15. <br></br>Voor een proefkaart 3 beurten betaal je € 40, op te nemen in 1 maand.<br></br>Voor een 10 beurten kaart betaal je € 130.<br></br>Iedere les duurt 1u15.</p>
            <p className='text-center mt-4'>Graag aanmelden via <a className='underline' href="mailto:yogametnadine@gmail.com">e-mail</a> of bericht. Gratis thee en napraten mogelijk na de les.</p>
        </div>
    </section>
  )
}

export default Prices